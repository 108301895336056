:root {
  /* globals */
  --white: #FFF; //Color del botón del switch
  --black: #000;
  --gray: #333;
  --red: #ec1848;
  --border-radius: 4px;
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);

  /* dark-mode */
  --page-header-bgColor: #004d89;//004d89
  --page-header-bgColor-hover: #4bb1ff;
  --page-header-txtColor: #dde9f8;
  --page-header-headingColor: #fff;
  --page-header-width: 220px;
  --page-content-bgColor: #f0f1f6;
  --page-content-txtColor: #171616;
  --page-content-blockColor: #fff; //Fondo del switch
  --switch-moonColor: #f4f4f4;
  --switch-bgDarkModeColor: #1f1f27;
  --button-bgnoSelected: #f6f9f7 ;
  --transition-duration: 0.5s ;

  /* light-mode */
  --switch-sunColor: gold;
  --switch-bgLightModeColor: #87cefa;
  --page-header-bgColor-lm: #292929;
  --page-header-bgColor-hover-lm: #6d6d6d;
  --page-header-txtColor-lm: #FFF;
  --page-header-headingColor-lm: #979595;
  --page-content-bgColor-lm: rgb(175, 162, 162);
  --tabs-content-bg-lm:#d0d0d0;
}


// estilo para filtros de select
@media (max-width: 915px) {
.filter-select-container{
 
 font-size: 5 !important;
}

.img_logo{
  
  width: 100px !important;
  min-width: 100px !important;
}


}
// estilos para react-data-table-component
.rd-row {
  min-height: 50px; /* Ajusta la altura mínima según tus necesidades */
}

.rd-table {
  table-layout: fixed;
}

.rd-pagination {
  display: flex;
  justify-content: center;
}

//estilo para el boton flotantee de cierre de la app en vista movil

.floating-button {
  position: fixed;
  top: 7px;
  left: 7px;
  z-index: 9999;
  width: 30px;
  height: 30px;
  background-color: var(--page-header-bgColor);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

// Typeahead clear button
.has-aux .form-control {
  padding-right: 2rem;

  // Position validation icons next to clear button and loader.
  &.is-valid,
  &.is-invalid {
    background-position: right 12rem center;
    padding-right: 4rem;
  }
}

//<------------reactQuill----------
div.blurred-editor div.ql-toolbar{
    display: none}

//<------------SelectedOption--------------------
@import url('https://rsms.me/inter/inter.css');

/**
 * Main wrapper
 */
.select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #004d89;;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Inter', sans-serif;

    width: 100%;
    position: relative;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
    .select-search-container {
        --select-search-font: 'Inter var', sans-serif;
    }
}

@media (prefers-color-scheme: dark) {
    .select-search-container {
        --select-search-background:#fff;// #000;
        --select-search-border: #dce0e8;// #313244;
        --select-search-selected:#004d89;;// #89b4fa;
        --select-search-text:  #000;//#fff;
        --select-search-subtle-text:  #6c6f85;//#a6adc8;
        --select-search-highlight: #eff1f5;// #1e1e2e;
    }


}


body.is-dark-mode .select-search-container {
    --select-search-background: #fff;// #000;
    --select-search-border:  #dce0e8;//#313244;
    --select-search-selected:#1e66f5;// #89b4fa;
    --select-search-text:#000;// #fff;
    --select-search-subtle-text: #6c6f85;// #a6adc8;
    --select-search-highlight: #eff1f5;//#1e1e2e;
}

body.is-light-mode .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    background: var(--select-search-background);
    border: 0px solid var(--select-search-border);
    color: var(--select-search-text);
    border-radius: 1px;
    outline: none;
    font-family: var(--select-search-font);
    text-align: left;
    text-overflow: ellipsis;
    line-height: 48px;
    letter-spacing: 0.01rem;
    appearance: none;
    -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
    border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search-input[readonly] {
    cursor: pointer;
}

.select-search-is-disabled .select-search-input {
    cursor: pointer ;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
    border-color: var(--select-search-selected);
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 1px solid var(--select-search-border);
    overflow: auto;
    max-height: 280px;

  
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: block;
}

.select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
  margin-left:-30px;
  width: 130%;
}

.select-search-option,
.select-search-not-found {
    display: block;
    height: 16px;
    width: 100%;
    padding: 1px 1px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    font-size: 10px;

    text-align: left;
    //letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
}

.select-search-is-selected {
    font-weight: bold;
    color: var(--select-search-selected);
}

.select-search-group-header {
    font-size: 46px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
    margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
}
//<-----------Floating Labels--------------------
$form-floating-height: calc(2.4rem + 2px);
$form-floating-line-height: 1.25;
$form-floating-padding-x: 0.25rem;
$form-floating-padding-y: 0.25rem;
$form-floating-input-padding-t: 0.95rem;
$form-floating-input-padding-b: 0.0rem;
$form-floating-label-opacity: 0.75;
$form-floating-label-transform: scale(0.70) translateY(-0.2rem) translateX(0.65rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;


/* -popup  signer------*/
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  max-width: 620px;
  min-width:  620px;
  border-radius: 10px;
  padding: 5px;
}


/* Acordeon -------- */

.accordion-button {
  background-color: var(--button-bgnoSelected) !important;
  height: 42px !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color:rgb(255, 255, 255) !important;
  background-color: #004d89 !important;
}

/*REACT TABS------*/

//---------------light mode pestañas
html.light-mode .react-tabs {
  background:var(--tabs-content-bg-lm);
}
html.light-mode .react-tabs__tab--selected {
  background:var(--tabs-content-bg-lm);
}
html.light-mode .react-tabs__tab--selected {
  background:var(--tabs-content-bg-lm);
padding-right: 0;
}


html.light-mode .accordion-body {
  background-color: var(--tabs-content-bg-lm) !important;
}
html.light-mode input {
  background-color: var(--tabs-content-bg-lm) !important;
}
html.light-mode textarea {
  background-color: var(--tabs-content-bg-lm) !important;
}
html.light-mode Frame {
  background-color: var(--tabs-content-bg-lm) !important;
}
html.light-mode .accordion-button:not(.collapsed) {
  background-color: var(--page-header-headingColor-lm) !important;
}



//--------------------------------------
.tabs-tittle {
  width: 20%;
  padding-top: 0.5rem;
  padding-left: 1rem;
  text-align: left;
}


.card-tabs {
  background: #000;
  height: 50px;
  padding: 0px;

}

.react-tabs {
  width: 100%;
  top: 5px;

  display: flexbox;

}

.react-tabs .card-header {
  padding: 0px;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  top: 5px;
  padding: 0;
  color: rgb(6, 6, 6);
  background: var(--button-bgnoSelected);
}

.react-tabs__tab-Row {

  display: flex;
  flex-direction: row;
  width: 170px;
  height: 40px;
  margin: 0;
  padding: 0;
  color: rgb(6, 6, 6);
  background:var(--button-bgnoSelected);
}

.react-tabs__tab {
  height: 40px;
  width: 13%;
  list-style: none;
  padding: 0px 0px, ;
  cursor: pointer;
  color: rgb(127, 127, 127);
  top: 10px;
}

.react-tabs__tab--selected {
  background: #ffffff;
  border-color: #000000;
  top: 10px;
  color: #010101;
}

.react-tabs__tab-panel {
  display: none;
  width: calc(100%)
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  width: 100%;
  text-align: center;
}

.collapsed .react-tabs {
  left: 50px;
  width: calc(100%);
}

/* Estilos para dispositivos móviles */
@media (max-width: 915px) {


  .tabs-tittle {
    width: 80px;
    padding-top: 0.80rem;
    padding-left: 0.5rem;
    font-size: 10px;
  }
  
  .card-tabs {
    height: 30px;
  }
  
  .react-tabs__tab-list {
    height: 35px;
  }
  
  .react-tabs__tab-Row {
    height: 30px;
  }
  
  .react-tabs__tab {
    height: 30px;
    width: 80px;
    font-size: 10px;
    top: 5px;
  }
  
  .react-tabs__tab--selected {
    top: 5px;
  }
  
  .panel-content {
    font-size: 14px;
  }
}


/* Avatar
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.avatar-content {
  width: 50%vw;

  align-self: center;

}

.collapsed .page-header{  

  .avatar {
    padding-top: 2px ;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    transform: translate(0px, -30px);
  }

  .admin-menu {
    overflow-x: hidden;
    i{
    transform: scale(1.3) translate(4px, 0px);
    transition: allvar( --transition-duration);
  }}
}


.admin-menu{
  margin-top: 20px;
}



.avatar {
  box-shadow: lightblue;
  text-shadow: 1px 1px 1px rgb(83, 83, 83);
  color: aliceblue;
  width: 70px;
  height: 70px;
  padding: 1px;
  border-radius: 50%;
  text-align: center;
  font-size: 2.9rem;
  transform: translate(0px, 0px);
  transition:all var( --transition-duration) ;

}
.avatar-xl {
  position: sticky;
  height: 1.5em;
  width: 1.5em;

  margin-top: 4%;
  box-shadow: lightblue;
  text-shadow: 2px 2px 9px #535353;
  color: aliceblue;
  border-radius: 50%;
  text-align: center;
  font-size: 9rem;
  transform: translate(0px, 0px);
  transition: ease;
}

@media screen and (max-width: 915px) {
  .avatar-xl {
    margin-top: 1%;
    font-size: 5rem;
  }
}

/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

.page-header * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.page-header ul {
  list-style: none;
}

.page-header a,
button {
  color: inherit;
}

.page-header a {
  text-decoration: none;
}

.page-header button {
  background: none;
  cursor: pointer;
}

.page-header input {
  appearance: none;
}

.page-header [type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.page-header label {
  cursor: pointer;
}

.page-header button,
input {
  border: none;
}

.page-header svg {
  display: block;
}

.page-header body {
  font: 16px/1.5 "Lato", sans-serif;
}


/* HEADER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hide;
  padding-top: 10px;
  width: var(--page-header-width);
  color: var(--page-header-txtColor);
  background: var(--page-header-bgColor);
  transition: all var( --transition-duration); //width 1s, left 1s, scale 1s;
}

.page-header nav {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page-header .logo {
  display: block;
  margin: 0 15px;
}

.page-header .logo svg {
  max-width: 120px;
  fill: var(--white);
}

.page-header .toggle-mob-menu {
  display: none;
  margin-left: 5px;
  padding: 4px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
}

.page-header .toggle-mob-menu svg {
  fill: var(--black);
  transition: transform 0.2s;
}

.page-header .admin-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 30px;
  padding: 0;

  span{
    opacity: 1;
    transition: all var( --transition-duration); //  opacity 0.5s ease-in-out, display 0s 0.5s;
  }
}

// .page-header .admin-menu li:nth-last-child(2) {
//   margin-bottom: 35px;
// }

// .page-header .admin-menu li:last-child {
//   margin-top: auto;
//   margin-bottom: 20px;
// }

.page-header .admin-menu li>* {
  width: 100%;
  padding: 12px 15px;
}

.page-header .admin-menu .switcher {
  display: inline-block;
  width: auto;
}

.page-header .admin-menu .menu-heading h3 {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 12px;
  margin-top: 12px;
  color: var(--page-header-headingColor);
}

.page-header .admin-menu svg {
  width: 20px;
  height: 20px;
  fill: var(--page-header-txtColor);
  margin-right: 10px;
}

.page-header .admin-menu a,
.page-header .admin-menu button {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.page-header .admin-menu a:hover,
.page-header .admin-menu a:focus,
.page-header .admin-menu button:hover,
.page-header .admin-menu button:focus {
  background: var(--page-header-bgColor-hover);
  color: var(--gray);
  outline: none;
}

.page-header .admin-menu a:hover svg,
.page-header .admin-menu a:focus svg,
.page-header .admin-menu button:hover svg,
.page-header .admin-menu button:focus svg {
  fill: var(--gray);
}


/* PAGE CONTENT STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-content {
  position: relative;
  left: var(--page-header-width);
  width: calc(100% - var(--page-header-width));
   min-height: 100vh;
   height: 100vh;
  padding: 30px;
  color: var(--page-content-txtColor);
  transition: all var( --transition-duration);// width 1s, left 1s, scale 1s;
  background: var(--page-content-bgColor);
}

.search-and-user {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background: var(--page-content-bgColor);
}

.search-and-user form {
  position: relative;
}

.search-and-user [type="search"] {
  width: 100%;
  height: 50px;
  font-size: 1.5rem;
  padding-left: 15px;
  background: var(--page-content-blockColor);
  color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.search-and-user ::placeholder {
  color: var(--page-content-txtColor);
}

.search-and-user form svg {
  width: 26px;
  height: 26px;
  fill: var(--page-content-txtColor);
}

.search-and-user form button {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.search-and-user .admin-profile {
  display: flex;
  align-items: center;
}

.search-and-user .admin-profile .greeting {
  margin: 0 10px 0 20px;
}

.search-and-user .admin-profile svg {
  width: 30px;
  height: 30px;
}

.search-and-user .admin-profile .notifications {
  position: relative;
}

.search-and-user .admin-profile .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 10px;
  color: var(--white);
  background: var(--red);
}

.page-content .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.page-content .grid>article {
  display: flex;
  height: 300px;
  background: var(--page-content-blockColor);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.page-content .grid>article:first-child,
.page-content .grid>article:last-child {
  grid-column: 1 / -1;
}


/* MQ RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 915px) {

  .page-header,
  .page-content {
    position: static;
    width: 100%;
  }

  .page-header {
    padding: 10px;
  }

  .page-header nav {
    flex-direction: row;
  }

  .page-header .logo {
    margin: 0;
  }

  .page-header .logo svg {
    width: 83px;
    height: 35px;
  }

  .page-header .toggle-mob-menu {
    display: block;
  }

  .page-header .admin-menu {
    position: absolute;
    left: 98px;
    top: 57px;
    margin-top: 0;
    z-index: 2;
    border-radius: var(--border-radius);
    background: var(--page-header-bgColor);
    visibility: hidden;
    opacity: 0;
    transform: scale(0.95);
    transition: all 0.2s;
  }

  .page-header .admin-menu li:nth-last-child(2) {
    margin-bottom: 12px;
  }

  .page-header .admin-menu li:last-child button,
  .search-and-user .admin-profile .greeting {
    display: none;
  }

  .page-content {
    min-height: 0;
    padding: 10px;
  }

  .page-content .grid {
    grid-gap: 10px;
  }

  .search-and-user {
    position: absolute;
    left: 131px;
    top: 10px;
    padding: 0;
    grid-column-gap: 5px;
    width: calc(100% - 141px);
    border-radius: var(--border-radius);
    background: transparent;
  }

  .search-and-user [type="search"] {
    font-size: 1rem;
    height: 35px;
  }

  .search-and-user form svg {
    width: 18px;
    height: 18px;
  }

  .search-and-user .admin-profile svg {
    fill: var(--white);
  }
}

@media screen and (max-width: 400px) {
  .page-content .grid>article {
    grid-column: 1 / -1;
  }
}


/* BODY CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.mob-menu-opened .toggle-mob-menu svg {
  transform: rotate(180deg);
}

.mob-menu-opened .page-header .admin-menu {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: 915px) {
  .collapsed .page-header {
    width: 50px;
    transition: all var( --transition-duration);  //width  1s, left 1s, scale 1s;
  }

  .collapsed .page-header .admin-menu li>* {
    padding: 10px;
  }

  .collapsed .page-header .logo,
  .collapsed .page-header .admin-menu span,
  .collapsed .page-header .admin-menu .menu-heading {
    display: none;
  }

  .collapsed .page-header .admin-menu svg {
    margin-right: 0;
  }

  .collapsed .page-header .collapse-btn svg {
    transform: rotate(180deg);
  }

  .collapsed .page-content {
    left: 50px; //<---------
    width: calc(100% - 50px);
  }
}


/* SWITCH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.switch label {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.switch span:first-child {
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: var(--switch-bgLightModeColor);
  transition: all 0.3s;
}

.switch span:first-child::before,
.switch span:first-child::after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.switch span:first-child::before {
  top: 1px;
  left: 1px;
  width: 24px;
  height: 24px;
  background: var(--white);
  z-index: 1;
  transition: transform 0.3s;
}

.switch span:first-child::after {
  top: 50%;
  right: 8px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  background: var(--switch-sunColor);
  box-shadow: 0 0 4px 2px #ffdb1a;
}

.switch [type="checkbox"]:checked+label span:first-child {
  background: var(--switch-bgDarkModeColor);
}

.switch [type="checkbox"]+label span:first-child {
  box-shadow: 0 3px 5px rgba(255, 255, 255, 0.25);
}

.switch [type="checkbox"]:checked+label span:first-child::before {
  transform: translateX(24px);
}

.switch [type="checkbox"]:checked+label span:first-child::after {
  left: 12px;
  width: 15px;
  height: 15px;
  background: transparent;
  box-shadow: -2px -5px 0 var(--switch-moonColor);
  transform: translateY(-50%) rotate(-72deg);
}


/* LIGHT MODE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.light-mode {
  --page-header-bgColor: var(--page-header-bgColor-lm);
  --page-header-bgColor-hover: var(--page-header-bgColor-hover-lm);
  --page-header-txtColor: var(--page-header-txtColor-lm);
  --page-header-headingColor: var(var(--page-header-headingColor-lm));
  --page-content-bgColor: var(--page-content-bgColor-lm);
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.25);
}

.light-mode .page-header .admin-menu a:hover,
.light-mode .page-header .admin-menu a:focus,
.light-mode .page-header .admin-menu button:hover,
.light-mode .page-header .admin-menu button:focus {
  color: var(--black);
}

.light-mode .page-header .logo svg,
.light-mode .page-header .admin-menu a:hover svg,
.light-mode .page-header .admin-menu a:focus svg,
.light-mode .page-header .admin-menu button:hover svg,
.light-mode .page-header .admin-menu button:focus svg {
  fill: var(--black);
}

.light-mode .switch [type="checkbox"]+label span:first-child {
  box-shadow: 0 3px 5px rgba(255, 255, 255, 0.25);
}

@media screen and (max-width: 915px) {
  .light-mode .search-and-user .admin-profile svg {
    fill: var(--black);
  }
}


/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.page-footer a {
  margin-left: 5px;
}


.form-check-input:disabled {
  background-color: gray;
}


 .dropdown-menu {
   min-width: 0 !important;
 }

.tabla .dropdown-menu.show {
  display: block;
  align-content: flex-start;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.dropdown-item {
  display: flex;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  align-items: flex-start !important;
  border-bottom: 1px solid rgb(218, 219, 221)!important;
}

.dropdown-item i {
  margin-right: 0.75rem;
}





.dropdown-toggle:empty::after {
  display: none
}

.dropdown-toggle {
  cursor: pointer;
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import   '../../node_modules/react-tabs/style/react-tabs.scss';
@import   '../../node_modules/react-perfect-scrollbar/dist/css/styles.css';